.loader {
  @apply bg-gray-200 rounded-full;
  width: 100%;
  height: 4px;
  position: relative;
  overflow: hidden;

  &-item {
    width: 50%;
  }

  &::before {
    @apply bg-blue-400;
    content: '';
    position: absolute;
    top: 0;
    left: -1000%;
    width: 100%;
    height: 100%;
    animation: loaderAnimation 1.5s ease-in-out infinite;
  }
  
  @keyframes loaderAnimation {
    0% {
      left: -100%;
    }
    100% {
      left: 100%;
    }
  }
}
